/* Google Font Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" , sans-serif;
}
.sidebar{
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background: #c3c3c3;
  padding: 3px 10px;
  z-index: 990;
  transition: all 0.5s ease;
}


.sidebar i{
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list{
  margin-top: 20px;
  height: 100%;
}
.sidebar li{
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip{
  position: absolute;
  top: -20px;
  right: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open li .tooltip
{
  display: none;
}



  .but{
      padding: 1px !important;
      border-radius: 50%;
      margin-top: 10px;
  }
 
 .activeButton {
    margin: 10px 10px;
    transition: all 0.5s;
    background: #c3c3c3;
    border-radius: 10px;
    padding:2px;
  }
  
  .sidebar.active {
    margin-right: -155px;
    transition: all 0.4s;
  }

 

.sidebar li a{
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #c3c3c3;
  color:rgb(138, 7, 7);
  font-weight: 500;
  margin-left: 7px;

}
.sidebar li a:hover{
  background: #FFF;
  color:rgb(0, 0, 0);
}


.sidebar li a:hover .links_name,
.sidebar li a:hover i{
  transition: all 0.5s ease;
  color: #11101D;
}
.sidebar li i{
  height: 50px;
  padding-right: 12px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
  color:rgb(138, 7, 7);
  margin-right: 10px;
}

.arrowRight i{
 border-top-left-radius: 20px;
 border-bottom-left-radius: 20px;
 /* background-color: #c3c3c3;*/
 color:rgb(138, 7, 7);
 margin: 0px 15px 0px 0px;
 padding: 0;
 font-size: 55px;
 position: absolute;
  top:10px;
  cursor: pointer;
  animation: rote 2s;
  animation: infin 1s infinite;
}


.arrowLeft i{
  border-top-right-radius: 20px;
 border-bottom-right-radius: 20px;
  /* background-color: #c3c3c3;*/
  color:rgb(138, 7, 7);
  margin: 0px 15px 0px 0px;
  font-size: 55px;
  transform: rotate(180deg);
  position: absolute;
  
  top:12px;
  cursor: pointer;
  animation: rote 2s;
  animation: infin 1s infinite;
 
}


  @keyframes infin{
    0%{
      right:210px;
    }
    50%{
      right: 213px;
    }
   
    100%{
      right:210px;
    }
  }







@media (min-width:642px) and (max-width: 767px){
  .marginTopResp{
    margin-top: 50px !important;
  }
  .theView{
    margin-top: 50px;
    margin-left: 20px;
  }

  .sidebar.active {
    width: 100px;
    margin-right: -28px;
    transition: all 0.4s;
  }
  .sidebar{
    position: fixed;
    right:10;
    top: 0;
    height: 100vh;
    width: 210px;
    background: #c3c3c3;
    padding: 3px 10px;
    z-index: 990;
    transition: all 0.5s ease;
   
  }
  @keyframes infin{
    0%{
      right:90px;
    }
    50%{
      right: 88px;
    }
   
    100%{
      right:90px;
    }
  }
   
  
}

@media(max-width:641px){

  .marginTopResp{
    margin-top: 50px !important;
  }

  .theView{
    margin-top: 50px;
    margin-left: 20px;
  }
  .sidebar.active {
    width: 100px;
    
    transition: all 0.4s;
  }
  .sidebar{
    position: fixed;
    right:10;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: #c3c3c3;
    padding: 3px 10px;
    z-index: 990;
    transition: all 0.5s ease;
   
  }
 
  .collapsed{
    position: relative;
    right:140px
}
.noncol{
  text-align: center;
}
.activeButton img {
  width: 250px;
}
.tooltip{
  visibility: hidden;
}

.arrowRight i{

   animation: right 1s infinite;
 }


 @keyframes right{
  0%{
  left:10px;
  }
  50%{
    left: 12px;
  }
 
  100%{
    left:10px;
  }
}

@keyframes infin{
  0%{
    right:70px;
  }
  50%{
    right: 68px;
  }
 
  100%{
    right:70px;
  }
}

}