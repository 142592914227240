.appointment-user-row {
    display: flex;
    margin-bottom: 10px;
    flex: 1 0 auto;
}

.appointment-user-row > div:not(:first-child) {
    flex-grow: 0;
    margin-left: 10px;
}

.appointment-user-row > div:first-child {
    flex-grow: 2;
}

.appointment-user-row > div .input-group.input-price {
    max-width: 130px;
}

.appointment-user-add {
    width: 100px;
}