@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.container{
  margin-top: 30px;
}
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand img {
  width: 45px;
}

.user-dropdown {
  width: 25px;
}

h3{
  font-size: 2rem !important;
}

.form-signin {
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 0 50px 50px;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  padding-top: 0;
}

.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}



.logo-login {
  margin: 0 20px;
  padding-top: 20px;
  max-width: 250px !important;
  margin-bottom: 20px;
}

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

#wrap, .wrapper {
  min-height: 100%
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

input:focus,
.css-yk16xz-control:focus-visible {
  border: 1px solid rgb(156, 20, 20)!important;
  box-shadow: rgba(114, 7, 7, 0.295) !important;
}
.css-2b097c-container:focus,
.css-1pahdxg-control:focus,
.css-1pahdxg-control:hover {
  border: 1px solid rgb(156, 20, 20)!important;
  border-radius: 20px;
  box-shadow: rgba(114, 7, 7, 0.295) !important;
}
 .form-control{
  background-color: rgba(226, 224, 224, 0.678);
  border-radius: 20px;
  box-shadow: rgba(114, 7, 7, 0.295) !important;
 }
 
 .css-yk16xz-control{
  background-color: rgba(226, 224, 224, 0.678) !important;
  border-radius: 20px !important;
  box-shadow: rgba(114, 7, 7, 0.295) !important;
 }

 label{
   margin-bottom: 2px !important;
   margin-left:10px !important ;
 }

 .css-g1d714-ValueContainer{
   border-color: none;
 }
 .css-b8ldur-Input,
 .css-g1d714-ValueContainer{
  border-color: none;
 }




.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

.logo {
  max-width: 90%;
  width: 300px;
}

.footer {
  background-color: #f8f9fa;
}

.footer:before {
  content:'';
  display: block;
  width:100%;
  height:1px;
  background: #ccc;
  top:-30px;
  left:0;
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgb(177, 176, 176);        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(133, 12, 12, 0.822);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  height: 10px;
}


.text-red {
  color: #d20606;
}

.footer > div {
  padding: 1rem 0;
}

.text-vhumanize {
  color: #4e73df;
}

.btn-vhumanize {
  color: white;
}
.btn{
  font-size: 0.9rem;
}

.background-oceandrive {
  background-color: #008ab0;
  color: #ffffff;
}

h1 > span, h2 > span {
  color: #36b9cc;
  text-decoration: underline;
  font-weight: 500;
}



.text-newskin > i, .form-group label > i, h1 > i, h2 > i, h3 > i, h4 > i, h5 > i {
  color: #ccc;
  -webkit-text-fill-color: #ccc;
}

.section-buttons {
  margin-left: auto;
  margin-top: 36px;
}

@media (max-width: 576px) {
  .form-signin {
    padding: 0 20px 50px;
  }

  .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .section-buttons {
    margin-left: auto;
    margin-top: 0px;
  }
}

th{
  background-color: rgba(182, 182, 182, 0.726);
}

.page-item.active .page-link {
  z-index: 1;
  color: rgb(68, 68, 68);
  background-color: rgba(182, 182, 182, 0.726);
  border: 1px solid #dddfeb;
}
.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: rgb(68, 68, 68);
  background-color: #fff;
  border: 1px solid #dddfeb;
}


.btn-success{
  background-color: seagreen;
  border: none;
}
.btn-success:hover{
  background-color: rgb(34, 104, 64);
  border: none;
}

.btn-warning{
  background-color: rgb(214, 131, 76);
  border: none;
}
.btn-warning:hover{
  background-color: rgb(155, 82, 33);
  border: none;
}

.btn-primary{
  background-color: rgb(110, 110, 110);
  border: none;
}

.btn-primary:hover{
  background-color: rgb(77, 77, 77);
  border: none;
}




.btn-minus{
  border-radius: 50%;
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}
.btn-minus:hover{
  border-radius: 50%;
  background-color:rgb(138, 25, 25)
}

.btn-dark{
  background-color: rgb(156, 20, 20);
  border:none
}
.btn-dark:hover{
  background-color: rgb(90, 13, 13);
  border:none
}
.info .body{
background-color: rgb(34, 104, 64);
}

.theView{
 width:85% ;
 margin-top:30px;
 margin-left: 70px;

}
/* Google Font Link */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" , sans-serif;
}
.sidebar{
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background: #c3c3c3;
  padding: 3px 10px;
  z-index: 990;
  transition: all 0.5s ease;
}


.sidebar i{
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list{
  margin-top: 20px;
  height: 100%;
}
.sidebar li{
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip{
  position: absolute;
  top: -20px;
  right: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open li .tooltip
{
  display: none;
}



  .but{
      padding: 1px !important;
      border-radius: 50%;
      margin-top: 10px;
  }
 
 .activeButton {
    margin: 10px 10px;
    transition: all 0.5s;
    background: #c3c3c3;
    border-radius: 10px;
    padding:2px;
  }
  
  .sidebar.active {
    margin-right: -155px;
    transition: all 0.4s;
  }

 

.sidebar li a{
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #c3c3c3;
  color:rgb(138, 7, 7);
  font-weight: 500;
  margin-left: 7px;

}
.sidebar li a:hover{
  background: #FFF;
  color:rgb(0, 0, 0);
}


.sidebar li a:hover .links_name,
.sidebar li a:hover i{
  transition: all 0.5s ease;
  color: #11101D;
}
.sidebar li i{
  height: 50px;
  padding-right: 12px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
  color:rgb(138, 7, 7);
  margin-right: 10px;
}

.arrowRight i{
 border-top-left-radius: 20px;
 border-bottom-left-radius: 20px;
 /* background-color: #c3c3c3;*/
 color:rgb(138, 7, 7);
 margin: 0px 15px 0px 0px;
 padding: 0;
 font-size: 55px;
 position: absolute;
  top:10px;
  cursor: pointer;
  -webkit-animation: rote 2s;
          animation: rote 2s;
  -webkit-animation: infin 1s infinite;
          animation: infin 1s infinite;
}


.arrowLeft i{
  border-top-right-radius: 20px;
 border-bottom-right-radius: 20px;
  /* background-color: #c3c3c3;*/
  color:rgb(138, 7, 7);
  margin: 0px 15px 0px 0px;
  font-size: 55px;
  transform: rotate(180deg);
  position: absolute;
  
  top:12px;
  cursor: pointer;
  -webkit-animation: rote 2s;
          animation: rote 2s;
  -webkit-animation: infin 1s infinite;
          animation: infin 1s infinite;
 
}


  @-webkit-keyframes infin{
    0%{
      right:210px;
    }
    50%{
      right: 213px;
    }
   
    100%{
      right:210px;
    }
  }


  @keyframes infin{
    0%{
      right:210px;
    }
    50%{
      right: 213px;
    }
   
    100%{
      right:210px;
    }
  }







@media (min-width:642px) and (max-width: 767px){
  .marginTopResp{
    margin-top: 50px !important;
  }
  .theView{
    margin-top: 50px;
    margin-left: 20px;
  }

  .sidebar.active {
    width: 100px;
    margin-right: -28px;
    transition: all 0.4s;
  }
  .sidebar{
    position: fixed;
    right:10;
    top: 0;
    height: 100vh;
    width: 210px;
    background: #c3c3c3;
    padding: 3px 10px;
    z-index: 990;
    transition: all 0.5s ease;
   
  }
  @-webkit-keyframes infin{
    0%{
      right:90px;
    }
    50%{
      right: 88px;
    }
   
    100%{
      right:90px;
    }
  }
  @keyframes infin{
    0%{
      right:90px;
    }
    50%{
      right: 88px;
    }
   
    100%{
      right:90px;
    }
  }
   
  
}

@media(max-width:641px){

  .marginTopResp{
    margin-top: 50px !important;
  }

  .theView{
    margin-top: 50px;
    margin-left: 20px;
  }
  .sidebar.active {
    width: 100px;
    
    transition: all 0.4s;
  }
  .sidebar{
    position: fixed;
    right:10;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: #c3c3c3;
    padding: 3px 10px;
    z-index: 990;
    transition: all 0.5s ease;
   
  }
 
  .collapsed{
    position: relative;
    right:140px
}
.noncol{
  text-align: center;
}
.activeButton img {
  width: 250px;
}
.tooltip{
  visibility: hidden;
}

.arrowRight i{

   -webkit-animation: right 1s infinite;

           animation: right 1s infinite;
 }


 @-webkit-keyframes right{
  0%{
  left:10px;
  }
  50%{
    left: 12px;
  }
 
  100%{
    left:10px;
  }
}


 @keyframes right{
  0%{
  left:10px;
  }
  50%{
    left: 12px;
  }
 
  100%{
    left:10px;
  }
}

@-webkit-keyframes infin{
  0%{
    right:70px;
  }
  50%{
    right: 68px;
  }
 
  100%{
    right:70px;
  }
}

@keyframes infin{
  0%{
    right:70px;
  }
  50%{
    right: 68px;
  }
 
  100%{
    right:70px;
  }
}

}

span.nav-link {
    cursor: pointer;
}

.navbar-oceandrive {
    position: relative;
    z-index: 999999;
}
.logotype{
    width: 250px;
    margin-top: 10px;
}

.topbar{
    height:100px;
}
.btn-meeting {
    margin-top: 22px;
    width: 100%;
}
.appointment-user-row {
    display: flex;
    margin-bottom: 10px;
    flex: 1 0 auto;
}

.appointment-user-row > div:not(:first-child) {
    flex-grow: 0;
    margin-left: 10px;
}

.appointment-user-row > div:first-child {
    flex-grow: 2;
}

.appointment-user-row > div .input-group.input-price {
    max-width: 130px;
}

.appointment-user-add {
    width: 100px;
}


.spinner{
    position:absolute;
    z-index: 3;
    
}
.background{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .logoload {
    margin: 0 auto;
    padding: 10px;
  }

  .logoloader {
    max-width: 90%;
    width: 100px;
    -webkit-animation: rotation 1.8s  infinite;
            animation: rotation 1.8s  infinite;

  }
  
  @-webkit-keyframes rotation{
    0%{
        transform:rotate3d( 0,0,0,0deg);
        opacity: 1;
    }
    50%{
        opacity: .4;
    }
    100%{
        transform:rotate3d(1,1,1,360deg);
        opacity: 1;
    }
}
  
  @keyframes rotation{
    0%{
        transform:rotate3d( 0,0,0,0deg);
        opacity: 1;
    }
    50%{
        opacity: .4;
    }
    100%{
        transform:rotate3d(1,1,1,360deg);
        opacity: 1;
    }
}

.btn-attachment {
    width: 150px;
}

.btn-dl {
    width: 130px;
}

.filename-text {
    font-size: 0.85rem;
    color: #aaa;
}
.customContainer{
    width: 200px !important;
    height: 100px !important;
  }
  .customText{
    font-size: 100px !important;
  }
h4 {
    margin: 2rem 0 1rem;
}

table .td, th {
    vertical-align: middle;
}

h3 {
    margin: 2rem 0 1rem;
}



/*CALENDAR*/

.calendar-todo-list li a{
    font-size: 1rem;
    font-weight: 600;
    color: rgb(199, 13, 13) !important;
}

.theSize{
min-height: 30px;
max-height: 150px;
min-width: 60px;
max-width: 350px;
overflow: scroll;
overflow-x: hidden;
}

.theSize::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
  .theSize::-webkit-scrollbar-track {
    background: rgb(177, 176, 176);        /* color of the tracking area */
  }
  
  .theSize::-webkit-scrollbar-thumb {
    background-color: rgba(133, 12, 12, 0.822);    /* color of the scroll thumb */
    border-radius: 4px;       /* roundness of the scroll thumb */
    border: 3px solid rgba(133, 12, 12, 0.822);  /* creates padding around scroll thumb */
  }
  

.rs-popover-content p{
    font-size: 0.9rem;
}

.rs-badge-independent.rs-badge-dot, .rs-badge-wrapper .rs-badge-content:empty{
    margin-right: 5px;
}

.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {

    border: 1px solid #af150a !important;

}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {

    background-color: #b94848 !important;
  
}

.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content{
    color:#af150a !important ;
    background-color: rgba(235, 231, 231, 0.747);
}

.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell-content{
    background-color: rgba(211, 211, 211, 0.747);
}

h4 {
    margin: 2rem 0 1rem;
}

table .td, th {
    vertical-align: middle;
}

h3 {
    margin: 2rem 0 1rem;
}

.inputError{
    margin-top: -18px;
    margin-left: 10px;
    color:red;
    font-weight: bold;
    font-size:0.9rem;
    width: 400px;
    
}
.must{
    font-size: 12px;
    font-style: italic;
    color: grey;
}

.nowrap{
    white-space: nowrap;
}
span.page-link {
    cursor: pointer;
}

.page-item.active .page-link{
    z-index: 0;
}
.user-image {
    padding: 50px 50px 0 50px;
    width: 100%;
}
