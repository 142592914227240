.btn-attachment {
    width: 150px;
}

.btn-dl {
    width: 130px;
}

.filename-text {
    font-size: 0.85rem;
    color: #aaa;
}