h4 {
    margin: 2rem 0 1rem;
}

table .td, th {
    vertical-align: middle;
}

h3 {
    margin: 2rem 0 1rem;
}

.inputError{
    margin-top: -18px;
    margin-left: 10px;
    color:red;
    font-weight: bold;
    font-size:0.9rem;
    width: 400px;
    
}
.must{
    font-size: 12px;
    font-style: italic;
    color: grey;
}

.nowrap{
    white-space: nowrap;
}