h4 {
    margin: 2rem 0 1rem;
}

table .td, th {
    vertical-align: middle;
}

h3 {
    margin: 2rem 0 1rem;
}



/*CALENDAR*/

.calendar-todo-list li a{
    font-size: 1rem;
    font-weight: 600;
    color: rgb(199, 13, 13) !important;
}

.theSize{
min-height: 30px;
max-height: 150px;
min-width: 60px;
max-width: 350px;
overflow: scroll;
overflow-x: hidden;
}

.theSize::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
  .theSize::-webkit-scrollbar-track {
    background: rgb(177, 176, 176);        /* color of the tracking area */
  }
  
  .theSize::-webkit-scrollbar-thumb {
    background-color: rgba(133, 12, 12, 0.822);    /* color of the scroll thumb */
    border-radius: 4px;       /* roundness of the scroll thumb */
    border: 3px solid rgba(133, 12, 12, 0.822);  /* creates padding around scroll thumb */
  }
  

.rs-popover-content p{
    font-size: 0.9rem;
}

.rs-badge-independent.rs-badge-dot, .rs-badge-wrapper .rs-badge-content:empty{
    margin-right: 5px;
}

.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {

    border: 1px solid #af150a !important;

}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {

    background-color: #b94848 !important;
  
}

.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content{
    color:#af150a !important ;
    background-color: rgba(235, 231, 231, 0.747);
}

.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell-content{
    background-color: rgba(211, 211, 211, 0.747);
}
