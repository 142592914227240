html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.container{
  margin-top: 30px;
}
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand img {
  width: 45px;
}

.user-dropdown {
  width: 25px;
}

h3{
  font-size: 2rem !important;
}

.form-signin {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 0 50px 50px;
  max-width: 450px;
  position: relative;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  padding-top: 0;
}

.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}



.logo-login {
  margin: 0 20px;
  padding-top: 20px;
  max-width: 250px !important;
  margin-bottom: 20px;
}

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

#wrap, .wrapper {
  min-height: 100%
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

input:focus,
.css-yk16xz-control:focus-visible {
  border: 1px solid rgb(156, 20, 20)!important;
  box-shadow: rgba(114, 7, 7, 0.295) !important;
}
.css-2b097c-container:focus,
.css-1pahdxg-control:focus,
.css-1pahdxg-control:hover {
  border: 1px solid rgb(156, 20, 20)!important;
  border-radius: 20px;
  box-shadow: rgba(114, 7, 7, 0.295) !important;
}
 .form-control{
  background-color: rgba(226, 224, 224, 0.678);
  border-radius: 20px;
  box-shadow: rgba(114, 7, 7, 0.295) !important;
 }
 
 .css-yk16xz-control{
  background-color: rgba(226, 224, 224, 0.678) !important;
  border-radius: 20px !important;
  box-shadow: rgba(114, 7, 7, 0.295) !important;
 }

 label{
   margin-bottom: 2px !important;
   margin-left:10px !important ;
 }

 .css-g1d714-ValueContainer{
   border-color: none;
 }
 .css-b8ldur-Input,
 .css-g1d714-ValueContainer{
  border-color: none;
 }




.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

.logo {
  max-width: 90%;
  width: 300px;
}

.footer {
  background-color: #f8f9fa;
}

.footer:before {
  content:'';
  display: block;
  width:100%;
  height:1px;
  background: #ccc;
  top:-30px;
  left:0;
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgb(177, 176, 176);        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(133, 12, 12, 0.822);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  height: 10px;
}


.text-red {
  color: #d20606;
}

.footer > div {
  padding: 1rem 0;
}

.text-vhumanize {
  color: #4e73df;
}

.btn-vhumanize {
  color: white;
}
.btn{
  font-size: 0.9rem;
}

.background-oceandrive {
  background-color: #008ab0;
  color: #ffffff;
}

h1 > span, h2 > span {
  color: #36b9cc;
  text-decoration: underline;
  font-weight: 500;
}



.text-newskin > i, .form-group label > i, h1 > i, h2 > i, h3 > i, h4 > i, h5 > i {
  color: #ccc;
  -webkit-text-fill-color: #ccc;
}

.section-buttons {
  margin-left: auto;
  margin-top: 36px;
}

@media (max-width: 576px) {
  .form-signin {
    padding: 0 20px 50px;
  }

  .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .section-buttons {
    margin-left: auto;
    margin-top: 0px;
  }
}

th{
  background-color: rgba(182, 182, 182, 0.726);
}

.page-item.active .page-link {
  z-index: 1;
  color: rgb(68, 68, 68);
  background-color: rgba(182, 182, 182, 0.726);
  border: 1px solid #dddfeb;
}
.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: rgb(68, 68, 68);
  background-color: #fff;
  border: 1px solid #dddfeb;
}


.btn-success{
  background-color: seagreen;
  border: none;
}
.btn-success:hover{
  background-color: rgb(34, 104, 64);
  border: none;
}

.btn-warning{
  background-color: rgb(214, 131, 76);
  border: none;
}
.btn-warning:hover{
  background-color: rgb(155, 82, 33);
  border: none;
}

.btn-primary{
  background-color: rgb(110, 110, 110);
  border: none;
}

.btn-primary:hover{
  background-color: rgb(77, 77, 77);
  border: none;
}




.btn-minus{
  border-radius: 50%;
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}
.btn-minus:hover{
  border-radius: 50%;
  background-color:rgb(138, 25, 25)
}

.btn-dark{
  background-color: rgb(156, 20, 20);
  border:none
}
.btn-dark:hover{
  background-color: rgb(90, 13, 13);
  border:none
}
.info .body{
background-color: rgb(34, 104, 64);
}

.theView{
 width:85% ;
 margin-top:30px;
 margin-left: 70px;

}